import React, { useState, useRef, useEffect } from "react";
import * as Images from "../../utilities/images";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { adminLogin, onErrorStopLoad } from "../../redux/slices/auth";
import { useAuthSelector } from "../../redux/selector/auth";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const [togglePassword, setTooglePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // toggle password
  const handleTogglePassword = () => {
    setTooglePassword(!togglePassword);
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // submit login from
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      showToast("Please enter your email");
      return;
    } else if (
      email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    } else if (!password) {
      showToast("Please enter your password");
      return;
    }
    let params = {
      email: email,
      password: password,
      platform:"superAdmin"
    };
    dispatch(
      adminLogin({
        ...params,
        cb(resss) {
          if (resss.status === 200) {
            navigate("/dashboard");
          }
        },
      })
    );
  };

  return (
    <>
      <div className="bg-auth-other">
        <div className="auth-main  login-details">
          <div className="auth-bg">
            <div className="auth-bg-inner-section">
              <div className="auth-contend-box ">
                <h1 className="auth-title">Log in to Allsundays!</h1>
                <p className="auth-paragraph">Enter your Log-in Credentials</p>
                <div className="auth-form-outer-update">
                  <form onSubmit={handleSubmit}>
                    <div className="auth-forms-input">
                      <label htmlFor="email">Email</label>
                      <div className="auth-input-img">
                        <input
                          placeholder="Email"
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <img
                          className="auth-icons"
                          alt="email-logo"
                          src={Images.email}
                        />
                      </div>
                    </div>

                    <div className="auth-forms-input">
                      <label htmlFor="password">Password</label>
                      <div className="auth-forms-input">
                        <div className="auth-input-img auth-input-password">
                          <input
                            placeholder="Password"
                            type={togglePassword ? "text" : "password"}
                            id="password"
                            name="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <img
                            className="auth-icons"
                            alt="password-logo"
                            src={Images.password}
                          />
                          <img
                            onClick={() => handleTogglePassword()}
                            className="auth-icons-password"
                            alt="password-logo"
                            src={
                              togglePassword
                                ? Images.showeye
                                : Images.hidePassword
                            }
                          />
                        </div>
                      </div>
                      <div className="remember-me-section text-align-right">
                        <Link to="/forgot-password" className="forgot-password">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>

                    <div className="submit-button-outer">
                      <button
                        disabled={loading}
                        type="submit"
                        className="auth-submit-btn"
                      >
                        Login
                        {loading && (
                          <span className="spinner-border spinner-border-sm ms-1"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
