import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Images from "../../utilities/images";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const { loading, churchId } = authData;
  const toastId = useRef(null);
  const [togglePassword, setToglePassword] = useState(false);
  const [toggleRetypePassword, setToggleRetypePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [reTypePassword, setReTypePassword] = useState("");

  // toggle password
  const handleTogglePassword = (type) => {
    if (type === "password") {
      setToglePassword(!togglePassword);
    } else {
      setToggleRetypePassword(!toggleRetypePassword);
    }
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      showToast("Please enter your password");
      return;
    } else if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
        password
      )
    ) {
      showToast(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    } else if (!reTypePassword) {
      showToast("Please enter your confirm password");
      return;
    } else if (password !== reTypePassword) {
      showToast("Password and confirm password should be the same");
      return;
    }
    let params = {
      new_password: password,
      confirm_password: reTypePassword,
      user_id: churchId?.user_id,
    };
    dispatch(
      resetPassword({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            navigate("/");
          }
        },
      })
    );
  };

  return (
    <>
      <div className="bg-auth-other">
        <div className="auth-main login-details">
          <div className="auth-bg">
            <div className="auth-bg-inner-section">
              <div className="auth-contend-box loginauth">
                <h1 className="auth-title">Create New Password</h1>
                <p className="auth-paragraph">
                  Please make a new password different from the previous
                  password.
                </p>
                <div className="auth-form-outer-update">
                  <form onSubmit={handleSubmit}>
                    <div className="auth-forms-input">
                      <label htmlFor="password">New Password</label>
                      <div className="auth-forms-input">
                        <div className="auth-input-img auth-input-password">
                          <input
                            placeholder="New Password"
                            type={togglePassword ? "text" : "password"}
                            id="password"
                            name="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <img
                            className="auth-icons"
                            alt="password-logo"
                            src={Images.password}
                          />
                          <img
                            onClick={() => handleTogglePassword("password")}
                            className="auth-icons-password"
                            alt="password-logo"
                            src={
                              togglePassword
                                ? Images.showeye
                                : Images.hidePassword
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="auth-forms-input">
                      <label htmlFor="password">Retype New Password</label>
                      <div className="auth-forms-input">
                        <div className="auth-input-img auth-input-password">
                          <input
                            placeholder="Retype New Password"
                            type={toggleRetypePassword ? "text" : "password"}
                            id="password"
                            name="Password"
                            onChange={(e) => setReTypePassword(e.target.value)}
                          />
                          <img
                            className="auth-icons"
                            alt="password-logo"
                            src={Images.password}
                          />
                          <img
                            onClick={() => handleTogglePassword("retype")}
                            className="auth-icons-password"
                            alt="password-logo"
                            src={
                              toggleRetypePassword
                                ? Images.showeye
                                : Images.hidePassword
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="submit-button-outer">
                      <button
                        disabled={loading}
                        type="submit"
                        className="auth-submit-btn"
                      >
                        Reset Password
                        {loading && (
                          <span className="spinner-border spinner-border-sm ms-1"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
