import React, { useEffect, useState } from "react";
import moment from "moment";
import { getChurchTransaction } from "../../redux/slices/web";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../redux/selector/web";
import { Link, useParams } from "react-router-dom";

const TransactionDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [churchTransactionDetail, setChurchTransactionDetail] = useState([]);

  // get church transaction details
  useEffect(() => {
    let params = {
      id: id,
    };
    dispatch(
      getChurchTransaction({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setChurchTransactionDetail(res?.data?.data?.users);
          }
        },
      })
    );
  }, []);

  // price formatter
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // table data
  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (item, index) => index + 1,
      reorder: true,
    },
    {
      id: 2,
      name: "Created At",
      selector: (item) => moment(item?.createdAt).format("MMMM D, YYYY"),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Transaction Id",
      selector: (item) => (
        <span title={item?.transactionId}>
          {item?.transactionId?.slice(0, 20)}...
        </span>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Status",
      selector: (item) => (
        <span
          className={
            item?.status ? "trans-succeeded text-capitalize" : "text-capitalize"
          }
        >
          {item?.status}
        </span>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Amount",
      selector: (item) => USDollar.format(item?.amount ? item?.amount : 0),
      sortable: true,
      right: "right",
      reorder: true,
    },
  ];

  return (
    <>
      <div className="content-wrapper adminUsers">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">
                  <Link to="/transactions-list">
                    <i className="fa fa-arrow-left back-to-church"></i>
                  </Link>
                  Transaction Details
                </h1>
              </div>
            </div>
          </div>
        </div>

        {/* Main content */}
        <section className="content commonBox_ position-relative userlistbox">
          <div className="container-fluid">
            <div className="table-outer">
              <DataTable
                columns={columns}
                data={churchTransactionDetail}
                defaultSortFieldId={1}
                sortIcon={<SortIcon />}
                pagination
                responsive={true}
                progressComponent={
                  <div className="spinner-grow " role="status"></div>
                }
                highlightOnHover={true}
                progressPending={loading}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TransactionDetails;
