const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/users/login",
    FORGOT_PASSWORD: "/users/forgot-password",
    RESET_PASSWORD: "/users/reset-password",
    LOGOUT_ADMIN: "/users/logOut",
    ENTER_OTP: "/users/reset-password-otp-verify",
    RSEND_ENTER_OTP: "/users/resend",
    ALL_CHURCH_LIST: "/users/get-church",
    CHURCH_PROFILE: "/users/get-church",
    GET_ALL_CHURCH_TRANSACTIONS: "/transactions/retrive-all",
    GET_CHURCH_TRANSACTION: "/transactions/retrive-all",
    CHANGE_CHURCH_STATUS: "/users/update-status",
  },
  webApiPath: {},
};

export default ApiPath;
