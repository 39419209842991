import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allChurchs } from "../../redux/slices/web";

const Dashboard = () => {
  document.title = "Home";
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState([]);
  console.log("usersData", usersData?.length);
  // getting all account information

  useEffect(() => {
    dispatch(
      allChurchs({
        cb(res) {
          if (res.status === 200) {
            setUsersData(res?.data?.data?.users);
          }
        },
      })
    );
  }, []);

  return (
    <div className="content-wrapper ">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}

      <section className="content commonBox_  admindash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box dashboxcolor">
                <div className="inner">
                  <h3>{usersData?.length ? usersData?.length : "---"}</h3>
                  <p>Churchs</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <a
                  // onClick={() => {
                  //   history.push("/user");
                  // }}
                  className="small-box-footer"
                >
                  <i className="fas " />
                </a>
              </div>
            </div>
            {/* <div className="col-lg-3 col-6">
              <div className="small-box dashboxcolor_two">
                <div className="inner">
                  <h3>10</h3>
                  <p>Chefs</p>
                </div>
                <div className="icon">
                  <i className="fas fa-bread-slice"></i>{" "}
                </div>
                <a className="small-box-footer">
                  <i className="fas " />
                </a>
              </div>
            </div> */}

            {/* <div className="col-lg-3 col-6">
              <div className="small-box bg-warning dashthree">
                <div className="inner">
                  <h3>20</h3>
                  <p>Support Tickets</p>
                </div>
                <div className="icon">
                  <i className="fa fa-sms" />
                </div>
                <a className="small-box-footer">
                  <i className="fas " />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
