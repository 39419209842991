import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    changeChurchStatus: (state) => {
      state.loading = true;
    },
    setChangeChurchStatus: (state) => {
      state.loading = false;
    },
    getChurchTransaction: (state) => {
      state.loading = true;
    },
    setGetChurchTransaction: (state) => {
      state.loading = false;
    },
    getAllTransactions: (state) => {
      state.loading = true;
    },
    setGetAllTransactions: (state) => {
      state.loading = false;
    },
    getChurchProfile: (state) => {
      state.loading = true;
    },
    setGetChurchProfile: (state) => {
      state.loading = false;
    },
    allChurchs: (state) => {
      state.loading = true;
    },
    setAllChurchs: (state) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  allChurchs,
  setAllChurchs,
  getChurchProfile,
  setGetChurchProfile,
  getAllTransactions,
  setGetAllTransactions,
  getChurchTransaction,
  setGetChurchTransaction,
  changeChurchStatus,
  setChangeChurchStatus,
  onErrorStopLoad,
} = webSlice.actions;

export default webSlice.reducer;
