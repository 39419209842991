import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  enterOtp,
  resendEnterOtp,
  onErrorStopLoad,
} from "../../redux/slices/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useAuthSelector } from "../../redux/selector/auth";

const EnterOtp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const { adminEmail, loading } = authData;
  const [isLoading, setIsLoading] = useState("");
  const [otp, setOtp] = useState("");

  // Form submit handler
  const handleSubmit = (e, flag) => {
    e.preventDefault();
    if (!otp) {
      toast.error("Please enter your OTP");
      return;
    }
    setIsLoading(flag);
    let params = {
      otp: otp,
    };
    dispatch(
      enterOtp({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/reset-password");
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // resend OTP

  const handleResendOtp = (e, flag) => {
    e.preventDefault();
    let params = {
      type: "forgot",
      email: adminEmail?.email,
    };
    setIsLoading(flag);
    dispatch(
      resendEnterOtp({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setOtp("");
          }
        },
      })
    );
  };

  return (
    <>
      <div className="bg-auth-other">
        <div className="auth-main">
          <div className="auth-bg">
            <div className="auth-bg-inner-section">
              <div className="auth-contend-box">
                <h1 className="auth-title">Recover Your Account</h1>
                <p className="auth-paragraph">
                  Please enter the 6 digit code we sent to your email
                </p>
                <div className="auth-form-outer-update">
                  <form onSubmit={(e) => handleSubmit(e, "submit")}>
                    <div className="auth-forms-verify">
                      <OTPInput
                        numInputs={6}
                        className="input_digits_"
                        inputStyle={{ justifyContent: "space-between" }}
                        value={otp}
                        isInputNum={true}
                        isInputSecure={true}
                        renderInput={(props) => (
                          <input {...props} type="number" maxLength={6} />
                        )}
                        onChange={setOtp}
                      />
                    </div>
                    <div className="mb-3 mt-3 resend_otp">
                      Don’t Received{" "}
                      {loading && isLoading === "resend" ? (
                        <>
                          {loading && isLoading === "resend" && (
                            <span className="spinner-border spinner-border-sm ms-1"></span>
                          )}
                        </>
                      ) : (
                        <span
                          onClick={(e) => handleResendOtp(e, "resend")}
                          className="resendLink"
                          href=""
                        >
                          Resend
                        </span>
                      )}
                    </div>
                    <div className="submit-button-outer">
                      <button
                        disabled={loading && isLoading === "submit"}
                        type="submit"
                        className="auth-submit-btn"
                      >
                        Submit
                        {loading && isLoading === "submit" && (
                          <span className="spinner-border spinner-border-sm ms-1"></span>
                        )}
                      </button>
                    </div>
                    <div className="auth-already-login">
                      <Link to="/">Back to Sign In</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOtp;
