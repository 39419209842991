import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Images from "../../utilities/images";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useAuthSelector();
  const { loading } = authSelector;
  const [email, setEmail] = useState("");

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter your email");
      return;
    }
    let params = {
      email,
    };
    dispatch(
      forgotPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/enter-otp");
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="bg-auth-other">
        <div className="auth-main login-details">
          <div className="auth-bg">
            <div className="auth-bg-inner-section">
              <div className="auth-contend-box loginauth">
                <h1 className="auth-title">Forgot Password</h1>
                <p className="auth-paragraph">
                  Enter your email and we'll email you instructions on how to
                  reset your password!
                </p>
                <div className="auth-form-outer-update">
                  <form onSubmit={handleSubmit}>
                    <div className="auth-forms-input">
                      <label htmlFor="email">Email</label>
                      <div className="auth-input-img">
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          type="email"
                          id="email"
                          name="email"
                        />
                        <img
                          className="auth-icons"
                          alt="email-logo"
                          src={Images.email}
                        />
                      </div>
                    </div>
                    <div className="submit-button-outer">
                      <button disabled={loading} type="submit" className="auth-submit-btn">
                        Send Email
                        {loading && (
                        <span className="spinner-border spinner-border-sm ms-1"></span>
                        )}
                      </button>
                    </div>
                    <div className="auth-already-login">
                      <Link to="/">Back to Sign In</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
