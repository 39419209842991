import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allChurchs, changeChurchStatus } from "../../redux/slices/web";
import { useWebSelector } from "../../redux/selector/web";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";

const ChurchList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [usersData, setUsersData] = useState([]);
  const [search, setSearch] = useState("");

  // getting all chef and user accounts
  useEffect(() => {
    getAllAccountsDetails();
  }, [search]);

  // getting all account information
  const getAllAccountsDetails = () => {
    let params = {
      search: search,
    };

    dispatch(
      allChurchs({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setUsersData(res?.data?.data?.users);
          }
        },
      })
    );
  };

  // go detail page
  const handleGetDetailPage = (id) => {
    navigate(`/member-list/${id}`);
  };

  // change church status
  const handleChangeChurchStatus = (id, status) => {
    let params = {
      id,
      status,
    };
    dispatch(
      changeChurchStatus({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            getAllAccountsDetails();
          }
        },
      })
    );
  };

  // table data
  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (user, index) => index + 1,
      reorder: true,
    },
    {
      id: 2,
      name: "Organization Name",
      selector: (user) => (
        <span className="text-capitalize">
          {user?.organizationName ? user?.organizationName : "---"}
        </span>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Contact Name",
      selector: (user) => (
        <span className="text-capitalize">
          {user?.contactName ? user?.contactName : "---"}
        </span>
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Email",

      selector: (user) => user?.email,
      sortable: true,
      reorder: true,
    },

    {
      id: 5,
      name: "Status ",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2 ">
          <div
            className={
              row?.deletedAt ? "inActive-user active-user" : "active-user"
            }
          ></div>
          <p
            className={
              row?.deletedAt
                ? "active-inactive-user inActive-inactive-user"
                : "active-inactive-user"
            }
          >
            {row?.deletedAt ? "InActive" : "Active"}
          </p>
        </div>
      ),
      right: "right",
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      selector: (user) => (
        <div className="dropdown filterDropdownChurchList">
          <button
            className="toggle-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          <ul className="dropdown-menu">
            <li
              onClick={() => {
                handleChangeChurchStatus(user?._id, "active");
              }}
            >
              <Link className="dropdown-item font-14 userText" to="#">
                Active
              </Link>
            </li>
            <li
              onClick={() => {
                handleChangeChurchStatus(user?._id, "inActive");
              }}
            >
              <Link className="dropdown-item font-14 userText" to="#">
                InActive
              </Link>
            </li>
            <li
              onClick={() => {
                handleGetDetailPage(user?._id);
              }}
            >
              <Link className="dropdown-item font-14 userText" to="#">
                View Members
              </Link>
            </li>
          </ul>
          {/* <i
            onClick={() => {
              handleGetDetailPage(user?._id);
            }}
            title="view members"
            className="fa fa-eye eye-icon"
          ></i> */}
        </div>
      ),
      right: "right",
    },
    // {
    //   id: 6,
    //   name: "Action",
    //   selector: (user) => (
    //     <div
    //       onClick={(e) => e.stopPropagation()}
    //       className="dropdown filterDropdown"
    //     >
    //       <button
    //         className="toggle-btn"
    //         type="button"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="fa fa-ellipsis-v"></i>
    //       </button>

    //       <ul className="dropdown-menu">
    //         <li
    //           onClick={() => {
    //             handleGetDetailPage(user?._id);
    //           }}
    //         >
    //           <Link className="dropdown-item font-14 userText" href="#">
    //             View Members
    //           </Link>
    //         </li>
    //       </ul>
    //       <i
    //         onClick={() => {
    //           handleGetDetailPage(user?._id);
    //         }}
    //         title="view members"
    //         className="fa fa-eye eye-icon"
    //       ></i>
    //     </div>
    //   ),
    //   right: "right",
    // },
  ];

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Church List</h1>
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <input
                className="input-elevated"
                type="text"
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <section className="content commonBox_ position-relative userlistbox">
        <div className="container-fluid">
          <div className="table-outer">
            <DataTable
              columns={columns}
              data={usersData}
              defaultSortFieldId={1}
              sortIcon={<SortIcon />}
              pagination
              responsive={true}
              progressComponent={
                <div className="spinner-grow " role="status"></div>
              }
              highlightOnHover={true}
              progressPending={loading}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChurchList;
