import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getChurchProfile } from "../../redux/slices/web";
import { Link } from "react-router-dom";
import { useWebSelector } from "../../redux/selector/web";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";

const MemberList = () => {
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const { id } = useParams();
  const [churchProfileInfo, setChurchProfileInfo] = useState([]);
  const [search, setSearch] = useState("");
  
  // get church profile information
  useEffect(() => {
    let params = {
      id: id,
      search: search,
    };
    dispatch(
      getChurchProfile({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setChurchProfileInfo(res?.data?.data?.users);
          }
        },
      })
    );
  }, [search]);


  // table data
  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (user, index) => index + 1,
      // sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "User Name",
      selector: (user) => (user?.userName ? user?.userName : "---"),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Email",
      selector: (user) => user?.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Role",
      selector: (user) => (user?.Roles?.slug ? user?.Roles?.slug : "---"),
      sortable: true,
      right: "right",
      reorder: true,
    },
    {
      id: 5,
      name: "Status ",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2 ">
          <div title="inActive" className="active-user"></div>
          <p className="active-inactive-user">Active</p>
        </div>
      ),
      right: "right",
      reorder: true,
    },
  ];

  return (
    <>
      <div className="content-wrapper adminUsers">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">
                  <Link to="/church-list">
                    <i className="fa fa-arrow-left back-to-church"></i>
                  </Link>
                  Member List
                </h1>
              </div>
              <div className="col-sm-6 d-flex justify-content-end">
                <input
                  className="input-elevated"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Main content */}
        <section className="content commonBox_ position-relative userlistbox">
          <div className="container-fluid">
            <div className="table-outer">
              <DataTable
                columns={columns}
                data={churchProfileInfo}
                defaultSortFieldId={1}
                sortIcon={<SortIcon />}
                pagination
                responsive={true}
                progressComponent={
                  <div className="spinner-grow " role="status"></div>
                }
                highlightOnHover={true}
                progressPending={loading}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default MemberList;
