import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../redux/selector/web";
import { allChurchs, getChurchTransaction } from "../../redux/slices/web";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { useNavigate } from "react-router-dom";

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [search, setSearch] = useState("");
  const [transactionList, setTransactionList] = useState([]);

  // get transation list
  useEffect(() => {
    let params = {
      search: search,
    };

    dispatch(
      allChurchs({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setTransactionList(res?.data?.data?.users);
          }
        },
      })
    );
  }, [search]);

  // table data
  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (user, index) => index + 1,
      reorder: true,
    },
    {
      id: 2,
      name: "Organization Name",
      selector: (user) =>
        user?.organizationName ? user?.organizationName : "---",
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Contact Name",
      selector: (user) => (user?.contactName ? user?.contactName : "---"),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Email",
      selector: (user) => user?.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Action",
      selector: (user) => (
        <i
          onClick={() => {
            handleGetDetailPage(user?._id);
          }}
          title="view church transactions"
          className="fa fa-eye eye-icon"
        ></i>
      ),
      right: "right",
    },
  ];

  // go detail page
  const handleGetDetailPage = (id) => {
    navigate(`/transaction-details/${id}`);
  };

  return (
    <>
      <div className="content-wrapper adminUsers">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Transaction List</h1>
              </div>
              <div className="col-sm-6 d-flex justify-content-end">
                <input
                  className="input-elevated"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Main content */}
        <section className="content commonBox_ position-relative userlistbox">
          <div className="container-fluid">
            <div className="table-outer">
              <DataTable
                columns={columns}
                data={transactionList}
                defaultSortFieldId={1}
                sortIcon={<SortIcon />}
                pagination
                responsive={true}
                progressComponent={
                  <div className="spinner-grow " role="status"></div>
                }
                highlightOnHover={true}
                progressPending={loading}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TransactionList;
