import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setAllChurchs,
  onErrorStopLoad,
  setGetChurchProfile,
  setGetAllTransactions,
  setGetChurchTransaction,
  setChangeChurchStatus,
} from "../../slices/web";

function* changeChurchStatus(action) {
  const deleteId = { ...action.payload };
  delete deleteId.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.AuthApiPath.CHANGE_CHURCH_STATUS}/${action.payload.id}`),
      (action.payload = deleteId)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setChangeChurchStatus(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getChurchTransaction(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.AuthApiPath.GET_CHURCH_TRANSACTION}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setGetChurchTransaction(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getAllTransactions(action) {
  // let mainUrl = `${ApiPath.AuthApiPath.GET_ALL_CHURCH_TRANSACTIONS}?limit=${action.payload.limit}&page=${action.payload.page}&`;
  let mainUrl = `${ApiPath.AuthApiPath.GET_ALL_CHURCH_TRANSACTIONS}`;
  if (action.payload.search) {
    mainUrl += `search=${action.payload.search}&`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = mainUrl),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setGetAllTransactions(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getChurchProfile(action) {
  const deleteId = { ...action.payload };
  delete deleteId.id;

  // let mainUrl = `${ApiPath.AuthApiPath.CHURCH_PROFILE}?churchId=${action.payload.id}&limit=${action.payload.limit}&page=${action.payload.page}&`;
  let mainUrl = `${ApiPath.AuthApiPath.CHURCH_PROFILE}?churchId=${action.payload.id}`;
  if (action.payload.search) {
    mainUrl += `&search=${action.payload.search}`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = mainUrl),
      (action.payload = deleteId)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setGetChurchProfile(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* allChurchs(action) {
  // let mainUrl = `${ApiPath.AuthApiPath.ALL_CHURCH_LIST}?limit=${action.payload.limit}&page=${action.payload.page}&`;
  let mainUrl = `${ApiPath.AuthApiPath.ALL_CHURCH_LIST}?`;
  if (action.payload.search) {
    mainUrl += `search=${action.payload.search}&`;
  }
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = mainUrl),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setAllChurchs(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([takeLatest("web/allChurchs", allChurchs)]);
  yield all([takeLatest("web/getChurchProfile", getChurchProfile)]);
  yield all([takeLatest("web/getAllTransactions", getAllTransactions)]);
  yield all([takeLatest("web/getChurchTransaction", getChurchTransaction)]);
  yield all([takeLatest("web/changeChurchStatus", changeChurchStatus)]);
}

export default webSaga;
