import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem("adminAuthToken"));

  if (!isAuthenticated) {
    return children;
  } else if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }
};

export default PublicRoute;
