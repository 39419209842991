import React from "react";
import { Link } from "react-router-dom";
import * as images from "../../src/utilities/images";

const Sidebar = () => {
  const pathName = window.location.pathname;

  return (
    <div>
      <aside className="main-sidebar sideBar-bg sidebar-primary elevation-4 main">
        <Link to="/dashboard" className="brand-link">
          <img
            src={images.brandLogo}
            className="brand_logo"
            alt="Brand Logo"
          />
        </Link>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              <li className="nav-item sideheading">
                <Link
                  to="/dashboard"
                  className={
                    ["/dashboard"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-th"></i>
                  <p>Dashboard</p>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  to="/church-list"
                  className={
                    ["/church-list"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-users"></i>
                  <p>Church-List</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/transactions-list"
                  className={
                    ["/transactions-list"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon	fa fa-credit-card"></i>
                  <p>Transactions</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
