import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainNav from "../common/MainNav";
import Sidebar from "../common/Sidebar";
import MainFooter from "../common/MainFooter";

const DashboardLayout = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const authRoutes = ["/"];

  return (
    <>
      <div className="mainBox">
        <Sidebar />
        <main className="main">
          <MainNav />
          <Outlet />
        </main>
      </div>
      <MainFooter />
    </>
  );
};

export default DashboardLayout;
