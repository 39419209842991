import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  loading: false,
  adminEmail: {},
  churchId: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetPassword: (state) => {
      state.loading = true;
    },
    setResetPassword: (state, action) => {
      state.loading = false;
    },
    resendEnterOtp: (state) => {
      state.loading = true;
    },
    setResendEnterOtp: (state, action) => {
      state.loading = false;
    },
    enterOtp: (state) => {
      state.loading = true;
    },
    setEnterOtp: (state, action) => {
      state.loading = false;
      state.churchId = action.payload;
    },
    forgotPassword: (state, action) => {
      state.loading = true;
      state.adminEmail = action.payload;
    },
    setForgotPassword: (state) => {
      state.loading = false;
    },
    adminLogin: (state) => {
      state.loading = true;
    },
    setAdminLogin: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  forgotPassword,
  setForgotPassword,
  adminLogin,
  setAdminLogin,
  onErrorStopLoad,
  enterOtp,
  setEnterOtp,
  resendEnterOtp,
  setResendEnterOtp,
  resetPassword,
  setResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
