import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const MainNav = () => {
  const navigate = useNavigate();
  
  // logout handler
  const handleLogout = () => {
    Swal.fire({
      className: "swal-logout",
      title: "Are you sure?",
      text: "That you want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#4A69F5",
      confirmButtonText: "Yes, Log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("adminAuthToken");
        toast.success("Logged out successfully")
        navigate("/");
      }
    });
  };

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light adminav dashboardNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="pushmenu"
              to="#"
              role="button"
            >
              <i className="fas text-light  fa-bars"></i>
            </Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <Link className="nav-link" data-toggle="dropdown" to="#">
              <i className="far text-light fa-user"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <div className="dropdown-divider"></div>
              <Link onClick={handleLogout} className="dropdown-item" to="#">
                <i className="fas  fa-sign-out-alt mr-2 droptext"></i> Logout
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="fullscreen"
              to="#"
              role="button"
            >
              <i className="fas text-light fa-expand-arrows-alt"></i>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default MainNav;
